<template>
  <section class="certification">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <el-row :gutter="30">
        <el-col :span="24" :offset="0"><div class="title"><h1>{{ options.title }}</h1><p><strong>{{ options.tit }}</strong></p></div></el-col>
      </el-row>
      <!-- <el-row :gutter="30">
        <ArticleContent :data="options.content" class="certification-content" />
      </el-row> -->
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="item in imgList" :key="item" style="text-align: center;">
          <el-image style="height: 100%" :src="item" fit="contain"></el-image>
        </el-carousel-item>
      </el-carousel>
    </section>
  </section>
</template>

<script>

import imageBanner from '../../components/ImageBanner.vue'
// import ArticleContent from '../../components/ArticleContent.vue'
export default {
  components: {imageBanner, /* ArticleContent */},
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/关于我们.png'),
        slogan: require('../../assets/images/2023/aboutus/slogan.png'),
      },
      options: {title: '', content: []},
      imgList: [
        require('../../assets/images/2023/aboutus/certification/1.企业信用等级证书AAA级.png'),
        // require('../../assets/images/2023/aboutus/certification/2.上海旋荣科技股份有限公司QMS-9001中.png'),
        // require('../../assets/images/2023/aboutus/certification/3.上海旋荣科技股份有限公司EMS-14001中.png'),
        // require('../../assets/images/2023/aboutus/certification/4.上海旋荣科技股份有限公司-20000.png'),
        // require('../../assets/images/2023/aboutus/certification/8.增值电信业务经营许可证-旋荣-27年4月1日过期_页面_1.png'),
        // require('../../assets/images/2023/aboutus/certification/9.知识产权许可凭证-旋荣.png'),
      ]
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      let id = (this.$route.query.id).toString()
      let option
      if ( id ) {
        switch ( id ) {
          case '5':
            option = this.$store.getters.getCertification && JSON.parse(JSON.stringify(this.$store.getters.getCertification))
            this.setOptions(option)
            break
          default:
            option = this.$store.state.getCertification
            this.setOptions(option)
        }
      }
    },
    setOptions (obj) {
      this.$set(this.options, 'title', obj.title)
      this.$set(this.options, 'content', obj.content)
      if (obj.tit) this.$set(this.options, 'tit', obj.tit)
      if (obj.url) this.$set(this.options, 'url', obj.url)
      if (obj.rightTitle) this.$set(this.options, 'rightTitle', obj.rightTitle)
      if (obj.rightContent) this.$set(this.options, 'rightContent', obj.rightContent)
    },
  }
}
</script>

<style scoped>
.certification {background-color: #ecf0f5; padding-bottom: 1rem;}
.certification .title {font-weight: 600; border-bottom: 1px solid #666; padding: 1rem 0;}
.certification .title h1 {font-size: 3rem; color: rgba(82, 0, 0, .9);line-height:1.6; font-weight: 600;}
.certification .certification-content {margin-top: 3rem;}
.certification .container /deep/ .el-carousel__container {height: 60rem;}
</style>